<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task mb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')"
            >
              Add Appointment
            </b-button>
          </div>
          <div
            class="add-task mb-1"
            style="text-align: center;"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="animate__animated animate__headShake animate__infinite"
            >
              <small>You have 1872 Outdated</small>
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
            style="margin-bottom: 20px;"
          >
            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="tag in taskTags"
                :key="tag.title"
              >
                <div>
                  <span
                    class="bullet bullet-sm mr-1"
                    :class="`bullet-${tag.color}`"
                  />
                  <small style="margin-right: 5px;"># 14875</small>
                  <small>{{ tag.title }}</small>
                </div>
                <div>
                  <small class="text-muted">1601 Outdated</small>
                </div>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import 'animate.css'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  data() {
    return {
      taskTags: [
        { title: 'Scheduled', color: 'primary' },
        { title: 'Confirmed', color: 'success' },
        { title: 'Re-Scheduled', color: 'warning' },
        { title: 'Ocurred', color: 'danger' },
        { title: 'No Show', color: 'info' },
        { title: 'Canceled', color: 'info' },
      ],
      isDynamicRouteActive,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
    }
  },
}
</script>

<style>

</style>
