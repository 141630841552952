<template>
  <div>
    <b-card>
      <div class="row">
        <div class="col-6 col-md-2">
          <b-form-radio
            v-model="Selected"
            v-b-tooltip.hover.bottom="'Appts set for today and next 5 days'"
            name="some-radio9"
            value="Priorities"
            class="custom-control-info"
          >
            Priorities
          </b-form-radio>
        </div>
        <div class="col-6 col-md-2">
          <b-form-radio
            v-model="Selected"
            v-b-tooltip.hover.bottom="'Appts that are Outdated'"
            name="some-radio9"
            value="Outdated"
            class="custom-control-info"
          >
            Outdated
          </b-form-radio>
        </div>
        <div class="col-6 col-md-2">
          <b-form-radio
            v-model="Selected"
            v-b-tooltip.hover.bottom="'Appts created today'"
            name="some-radio9"
            value="CreateToday"
            class="custom-control-info"
          >
            Create Today
          </b-form-radio>
        </div>
        <div class="col-6 col-md-2">
          <b-form-radio
            v-model="Selected"
            v-b-tooltip.hover.bottom="'Appts created this week'"
            name="some-radio9"
            value="CreatedThisWeek"
            class="custom-control-info"
          >
            Created This Week
          </b-form-radio>
        </div>
        <div class="col-6 col-md-2">
          <b-form-radio
            v-model="Selected"
            v-b-tooltip.hover.bottom="'Set filters to your own needs'"
            name="some-radio9"
            value="CustomSearch"
            class="custom-control-info"
          >
            Custom Search
          </b-form-radio>
        </div>
        <div class="col-6 col-md-2">
          <b-form-radio
            v-model="Selected"
            v-b-tooltip.hover.bottom="'Check your day'"
            name="some-radio9"
            value="DaySummary"
            class="custom-control-info"
          >
            Day Summary
          </b-form-radio>
        </div>
      </div>
    </b-card>
    <b-collapse
      id="collapse-1"
      style="margin-top: -15px !important; margin-bottom: 30px;"
    >
      <b-card class="border mb-0">
        <b-row>
          <b-col md="4">
            <div
              class="mb-1"
              style="font-weight: 500;"
            >
              Appt For
            </div>
            <b-input-group>
              <flat-pickr
                v-model="dateDefault"
                class="form-control"
                :config="{ mode: 'range'}"
              />
              <b-input-group-append>
                <b-button variant="outline-primary">
                  <feather-icon icon="CalendarIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-select
              v-model="selected"
              :options="options"
              size="sm"
              class="mt-1"
            />
          </b-col>
          <b-col md="4">
            <div
              class="mb-1"
              style="font-weight: 500;"
            >
              Created On
            </div>
            <b-input-group>
              <flat-pickr
                v-model="dateDefault"
                class="form-control"
                :config="{ mode: 'range'}"
              />
              <b-input-group-append>
                <b-button variant="outline-primary">
                  <feather-icon icon="CalendarIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-select
              v-model="selected"
              :options="options"
              size="sm"
              class="mt-1"
            />
          </b-col>
          <b-col md="4">
            <div
              class="mb-1"
              style="font-weight: 500;"
            >
              Representatives
            </div>
            <b-form-select
              v-model="selected"
              :options="options"
              class="mb-1"
            />
            <b-button
              variant="warning"
              class="mr-1 mb-1"
              size="sm"
            >
              <feather-icon
                icon="HexagonIcon"
                class="mr-50"
              />
              <span class="align-middle">Go Back To Interactive Report</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <!-- Table Appointment Card -->
    <b-card
      v-if="showDaySummary == false"
      no-body
      style="margin-top: -15px !important; margin-bottom: 15px !important;"
    >

      <div class="m-2">
        <!-- Table Top -->
        <left-side-bar />
        <b-row style="padding-left: 10px !important;">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :options="PageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="handleSearch"
              />
              <v-select
                v-model="TypeProfileValue"
                :options="TypeofProfile"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
          <!-- Table -->
          <b-table
            ref="tableAppointments"
            style="margin-left: 15px; margin-right: 15px; margin-top: 30px; border-radius: 5px; height: 600px;"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            responsive
            @sort-changed="onSortChange"
          >

            <!-- Column: Patient -->
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    class="border"
                    size="32"
                    :class="'BAvatarPatientProfiles'"
                    :text="avatarText(data.value)"
                    :to="{ name: 'kardex-profile', params: { idPatient: data } }"
                  />
                </template>
                <span class="font-weight-bold text-nowrap">
                  {{ `${data.value}` }}
                </span>
                <small class="text-muted">15-02-99</small>
              </b-media>
            </template>

            <!-- Column: ApptDate -->
            <template #cell(ApptDate)="data">
              <span class="text-nowrap">
                {{ data.value | date-format }}
              </span>
            </template>

            <!-- Column: Patient -->
            <template #cell(Notes)="data">
              <span class="text-nowrap">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-popover.focus="`${data.value}`"
                  v-b-popover.hover="`${data.value}`"
                  variant="outline-primary"
                  style="padding: 5px;"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="15"
                  />
                </b-button>
              </span>
            </template>

            <!-- Column: VisitType -->
            <template #cell(VisitType)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>

            <!-- Column: Site -->
            <template #cell(Site)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>

            <!-- Column: CreatedBy -->
            <template #cell(CreatedBy)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>

            <!-- Column: CreatedBy -->
            <template #cell(CreatedOn)="data">
              <span class="text-nowrap">
                {{ data.value | date-format }}
              </span>
            </template>

            <!-- Column: Status -->
            <template #cell(Status)>
              <span class="text-nowrap">
                <!-- drop left -->
                <b-dropdown
                  id="dropdown-dropleft"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  dropleft
                  text="Re-Scheduled"
                  size="sm"
                  menu-class="dropdown-menu-appointments"
                >
                  <b-dropdown-item>
                    <!-- size -->
                    <b-button-group
                      size="sm"
                    >
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        style="background-color: #7BD5F5; color: white; border: none !important;"
                      >
                        Ocurred
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        style="background-color: #8474A1; color: white; border: none !important;"
                      >
                        No Show
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        style="background-color: #CCABD8; color: white; border: none !important;"
                      >
                        Cancel
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        style="background-color: #329D9C; color: white; border: none !important;"
                      >
                        Confirm
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        style="background-color: #F88973; color: white; border: none !important;"
                      >
                        Re-Scheduled
                      </b-button>
                    </b-button-group>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>

            <!-- Column: Status -->
            <template #cell(Actions)>
              <div class="text-nowrap">
                <feather-icon
                  :id="`invoice-row-send-icon`"
                  v-b-tooltip.hover.bottom="'Appts created this week'"
                  icon="EditIcon"
                  class="cursor-pointer"
                  size="15"
                />
                <feather-icon
                  :id="`invoice-row-preview-icon`"
                  v-b-tooltip.hover.bottom="'Appts created this week'"
                  icon="EyeIcon"
                  size="15"
                  class="mx-1"
                />
              </div>
            </template>

          </b-table>
        </b-row>
      </div>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :current-page="currentPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>
    <b-card
      v-if="showDaySummary == true"
      style="margin-top: -15px !important; margin-bottom: 15px !important;"
    >
      <b-row>
        <b-col md="2">
          <div
            class="mb-1"
            style="font-weight: 500;"
          >
            Appt Date
          </div>
          <b-input-group>
            <flat-pickr
              v-model="dateDefault"
              class="form-control"
              :config="{ mode: 'range'}"
            />
            <b-input-group-append>
              <b-button variant="outline-primary">
                <feather-icon icon="CalendarIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="2">
          <div
            class="mb-1"
            style="font-weight: 500;"
          >
            Rep
          </div>
          <b-form-select
            v-model="selected"
            :options="options"
          />
        </b-col>
        <b-col md="2">
          <div
            class="mb-1"
            style="font-weight: 500;"
          >
            Status
          </div>
          <b-form-select
            v-model="selected"
            :options="options"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col col="12">
          <app-collapse
            type="margin"
          >
            <app-collapse-item title="0 Visits">
              <div>
                <!-- Table Top -->
                <b-row style="padding-left: 10px !important;">
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Entries</label>
                    <v-select
                      v-model="perPage"
                      :options="PageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                  </b-col>
                  <!-- Search -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex align-items-center justify-content-end">
                      <b-form-input
                        v-model="searchTerm"
                        class="d-inline-block mr-1"
                        placeholder="Search..."
                        @input="handleSearch"
                      />
                    </div>
                  </b-col>
                  <!-- Table -->
                  <b-table
                    ref="tableAppointments"
                    style="margin-left: 15px; margin-right: 30px; margin-top: 30px; border-radius: 5px; height: 400px;"
                    :items="items"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    responsive
                    @sort-changed="onSortChange"
                  >

                    <!-- Column: Patient -->
                    <template #cell(name)="data">
                      <b-media vertical-align="center">
                        <template #aside>
                          <b-avatar
                            class="border"
                            size="32"
                            :class="'BAvatarPatientProfiles'"
                            :text="avatarText(data.value)"
                            :to="{ name: 'kardex-profile', params: { idPatient: data } }"
                          />
                        </template>
                        <span class="font-weight-bold text-nowrap">
                          {{ `${data.value}` }}
                        </span>
                        <small class="text-muted">15-02-99</small>
                      </b-media>
                    </template>

                    <!-- Column: ApptDate -->
                    <template #cell(ApptDate)="data">
                      <span class="text-nowrap">
                        {{ data.value | date-format }}
                      </span>
                    </template>

                    <!-- Column: Patient -->
                    <template #cell(Notes)="data">
                      <span class="text-nowrap">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-popover.focus="`${data.value}`"
                          v-b-popover.hover="`${data.value}`"
                          variant="outline-primary"
                          style="padding: 5px;"
                        >
                          <feather-icon
                            icon="FileIcon"
                            size="15"
                          />
                        </b-button>
                      </span>
                    </template>

                    <!-- Column: VisitType -->
                    <template #cell(VisitType)="data">
                      <span class="text-nowrap">
                        {{ data.value }}
                      </span>
                    </template>

                    <!-- Column: Site -->
                    <template #cell(Site)="data">
                      <span class="text-nowrap">
                        {{ data.value }}
                      </span>
                    </template>

                    <!-- Column: CreatedBy -->
                    <template #cell(CreatedBy)="data">
                      <span class="text-nowrap">
                        {{ data.value }}
                      </span>
                    </template>

                    <!-- Column: CreatedBy -->
                    <template #cell(CreatedOn)="data">
                      <span class="text-nowrap">
                        {{ data.value | date-format }}
                      </span>
                    </template>

                    <!-- Column: Status -->
                    <template #cell(Status)>
                      <span class="text-nowrap">
                        <!-- drop left -->
                        <b-dropdown
                          id="dropdown-dropleft"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          dropleft
                          text="Re-Scheduled"
                          size="sm"
                          menu-class="dropdown-menu-appointments"
                        >
                          <b-dropdown-item>
                            <!-- size -->
                            <b-button-group
                              size="sm"
                            >
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                style="background-color: #7BD5F5; color: white; border: none !important;"
                              >
                                Ocurred
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                style="background-color: #8474A1; color: white; border: none !important;"
                              >
                                No Show
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                style="background-color: #CCABD8; color: white; border: none !important;"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                style="background-color: #329D9C; color: white; border: none !important;"
                              >
                                Confirm
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                style="background-color: #F88973; color: white; border: none !important;"
                              >
                                Re-Scheduled
                              </b-button>
                            </b-button-group>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </template>

                    <!-- Column: Status -->
                    <template #cell(Actions)>
                      <div class="text-nowrap">
                        <feather-icon
                          :id="`invoice-row-send-icon`"
                          v-b-tooltip.hover.bottom="'Appts created this week'"
                          icon="EditIcon"
                          class="cursor-pointer"
                          size="15"
                        />
                        <feather-icon
                          :id="`invoice-row-preview-icon`"
                          v-b-tooltip.hover.bottom="'Appts created this week'"
                          icon="EyeIcon"
                          size="15"
                          class="mx-1"
                        />
                      </div>
                    </template>

                  </b-table>
                </b-row>
              </div>
              <div class="mx-2 mb-2">
                <b-row>

                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      :current-page="currentPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @change="handleChangePage"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-collapse-item>

            <app-collapse-item title="Appointments Created">
              <div>
                <!-- Table Top -->
                <b-row style="padding-left: 10px !important;">
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Entries</label>
                    <v-select
                      v-model="perPage"
                      :options="PageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                  </b-col>
                  <!-- Search -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex align-items-center justify-content-end">
                      <b-form-input
                        v-model="searchTerm"
                        class="d-inline-block mr-1"
                        placeholder="Search..."
                        @input="handleSearch"
                      />
                    </div>
                  </b-col>
                  <!-- Table -->
                  <b-table
                    ref="tableAppointments"
                    style="margin-left: 15px; margin-right: 30px; margin-top: 30px; border-radius: 5px; height: 400px;"
                    :items="items"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    responsive
                    @sort-changed="onSortChange"
                  >

                    <!-- Column: Patient -->
                    <template #cell(name)="data">
                      <b-media vertical-align="center">
                        <template #aside>
                          <b-avatar
                            class="border"
                            size="32"
                            :class="'BAvatarPatientProfiles'"
                            :text="avatarText(data.value)"
                            :to="{ name: 'kardex-profile', params: { idPatient: data } }"
                          />
                        </template>
                        <span class="font-weight-bold text-nowrap">
                          {{ `${data.value}` }}
                        </span>
                        <small class="text-muted">15-02-99</small>
                      </b-media>
                    </template>

                    <!-- Column: ApptDate -->
                    <template #cell(ApptDate)="data">
                      <span class="text-nowrap">
                        {{ data.value | date-format }}
                      </span>
                    </template>

                    <!-- Column: Patient -->
                    <template #cell(Notes)="data">
                      <span class="text-nowrap">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-popover.focus="`${data.value}`"
                          v-b-popover.hover="`${data.value}`"
                          variant="outline-primary"
                          style="padding: 5px;"
                        >
                          <feather-icon
                            icon="FileIcon"
                            size="15"
                          />
                        </b-button>
                      </span>
                    </template>

                    <!-- Column: VisitType -->
                    <template #cell(VisitType)="data">
                      <span class="text-nowrap">
                        {{ data.value }}
                      </span>
                    </template>

                    <!-- Column: Site -->
                    <template #cell(Site)="data">
                      <span class="text-nowrap">
                        {{ data.value }}
                      </span>
                    </template>

                    <!-- Column: CreatedBy -->
                    <template #cell(CreatedBy)="data">
                      <span class="text-nowrap">
                        {{ data.value }}
                      </span>
                    </template>

                    <!-- Column: CreatedBy -->
                    <template #cell(CreatedOn)="data">
                      <span class="text-nowrap">
                        {{ data.value | date-format }}
                      </span>
                    </template>

                    <!-- Column: Status -->
                    <template #cell(Status)>
                      <span class="text-nowrap">
                        <!-- drop left -->
                        <b-dropdown
                          id="dropdown-dropleft"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          dropleft
                          text="Re-Scheduled"
                          size="sm"
                          menu-class="dropdown-menu-appointments"
                        >
                          <b-dropdown-item>
                            <!-- size -->
                            <b-button-group
                              size="sm"
                            >
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                style="background-color: #7BD5F5; color: white; border: none !important;"
                              >
                                Ocurred
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                style="background-color: #8474A1; color: white; border: none !important;"
                              >
                                No Show
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                style="background-color: #CCABD8; color: white; border: none !important;"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                style="background-color: #329D9C; color: white; border: none !important;"
                              >
                                Confirm
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                style="background-color: #F88973; color: white; border: none !important;"
                              >
                                Re-Scheduled
                              </b-button>
                            </b-button-group>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </template>

                    <!-- Column: Status -->
                    <template #cell(Actions)>
                      <div class="text-nowrap">
                        <feather-icon
                          :id="`invoice-row-send-icon`"
                          v-b-tooltip.hover.bottom="'Appts created this week'"
                          icon="EditIcon"
                          class="cursor-pointer"
                          size="15"
                        />
                        <feather-icon
                          :id="`invoice-row-preview-icon`"
                          v-b-tooltip.hover.bottom="'Appts created this week'"
                          icon="EyeIcon"
                          size="15"
                          class="mx-1"
                        />
                      </div>
                    </template>

                  </b-table>
                </b-row>
              </div>
              <div class="mx-2 mb-2">
                <b-row>

                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      :current-page="currentPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @change="handleChangePage"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormRadio, VBTooltip, VBToggle, BCollapse, BAvatar, BRow, BCol, BFormInput, BPagination, BTable, BMedia, VBPopover, BButton, BDropdown, BDropdownItem, BButtonGroup, BInputGroup, BInputGroupAppend, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import LeftSideBar from './LeftSideBarStatus.vue'

export default {
  components: {
    BCard,
    BFormRadio,
    BCollapse,
    BAvatar,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BTable,
    BMedia,
    vSelect,
    LeftSideBar,
    BButton,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      selected: null,
      options: [],
      Selected: 'Priorities',
      Toogle: false,
      avatarText,
      dateDefault: null,
      fields: [{ key: 'name', label: 'Patient Name', sortable: true }, { key: 'ApptDate', sortable: true }, { key: 'Notes', sortable: true }, { key: 'VisitType', sortable: true }, { key: 'Site', sortable: true }, { key: 'CreatedBy', sortable: true }, { key: 'CreatedOn', sortable: true }, { key: 'Status', sortable: true }, { key: 'Actions', sortable: true }],
      items: [{
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      {
        name: 'Eduardo Bedolla', ApptDate: '12-02-1888', Notes: 'Notes notes notes', VisitType: 'ESTPT F/U', Site: 'Fontana 17500 (New)', CreatedBy: 'Valentina Hoyos', CreatedOn: '09-07-1999',
      },
      ],
      TypeProfileValue: '',
      TypeofProfile: ['Profiles', 'Leads'],
      type: '',
      perPage: 10,
      PageOptions: [10, 25, 50, 100],
      actualpage: 1,
      totalRows: 0,
      currentPage: 1,
      FullCountEntries: 0,
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      searchTerm: '',
      showDaySummary: false,
    }
  },
  watch: {
    Selected(val) {
      console.log(val)
      if (val !== 'CustomSearch') {
        if (this.Toogle === true) {
          this.$root.$emit('bv::toggle::collapse', 'collapse-1')
          this.Toogle = false
        }
      } else if (this.Toogle === false) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-1')
        this.Toogle = true
      }
      if (val !== 'DaySummary') {
        this.showDaySummary = false
      } else {
        this.showDaySummary = true
      }
    },
  },
  methods: {
    handleSearch(searching) {
      console.log(searching)
    },
    handleChangePage(page) {
      console.log(page)
    },
    onSortChange(params) {
      console.log(params)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style>
.dropdown-menu-appointments{
  background-color: transparent !important;
  border: none;
  box-shadow: 0 0px 0px rgb(34 41 47 / 10%);
}

.dropdown-menu-appointments .dropdown-item{
  background-color: transparent !important;
  margin-top: -15px;
}
</style>
